import React from "react";
import { Title } from "../../../components";

const LinuxVps: React.FC = () => (
  <>
    <Title text="Linux VPS" />
  </>
);

export default LinuxVps;
