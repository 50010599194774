import React from "react";
import { Title } from "../../../components";

const OverwatchTools: React.FC = () => (
  <>
    <Title text="Overwatch Tools" />
  </>
);

export default OverwatchTools;
