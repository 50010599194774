import React from "react";

import { Title, ProjectDisplay } from "../../../components";

const PortfolioHome: React.FC = () => {
  return (
    <>
      <Title text="Portfolio" />
      <ProjectDisplay />
    </>
  );
};

export default PortfolioHome;
